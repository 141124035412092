import { getStorage } from 'utils/storage'
import axios from 'axios'
import { alertDanger } from 'utils/toast'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
})

api.interceptors.request.use(
  async (config: any) => {
    const token = getStorage('@ManagerStarbem:token')

    config.headers.Authorization = token
      ? `Bearer ${token}`
      : `Basic ${process.env.REACT_APP_API_KEY}`
    return config
  },
  (error: any) => Promise.reject(error)
)

api.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const { status } = error.response

    if ([403, 401].includes(status)) {
      window.localStorage.clear()
      alertDanger('Sua sessão expirou! Faça login novamente.')

      setTimeout(() => {
        window.location.reload()
      }, 1500)
    }

    Promise.reject(error)
  }
)

export default api
