import React, { useState, useEffect } from 'react'

import {
  Modal,
  ModalHeader,
  ModalBody,
  Badge,
  Media,
  Progress,
  Table,
  Button,
  Row,
  Col,
} from 'reactstrap'
import Loading from 'components/Loading'
import { useExcelDownloder } from 'react-xls'
import moment from 'moment'

import api from 'configs/api'

const ModalUserImport = ({ id, modal, setModal }: any) => {
  const { ExcelDownloder, Type } = useExcelDownloder()
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<any>()
  const [download, setDownload] = useState<any>({
    id: 0,
    data: null,
  })

  const toggle = () => {
    setModal('')
  }

  const getData = async () => {
    setLoading(true)
    try {
      const response = await api.get(`accounts/ms/v1/user/import/history/${id}`)
      setData(response?.data)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (id) {
      getData()
    }
  }, [id])

  const creatXlsx = () => {
    const usersWithErrors = data?.users?.filter((user: any) => {
      const statuses = [user.user.status, user.plan.status, user.company.status]
      return statuses.includes('failed')
    })

    const xlsx = []
    const info = [
      'Atualize os dados da planilha modelo de importação para realizar uma nova importação',
    ]
    const headers = ['Nome', 'E-mail', 'Celular', 'CPF', 'Erros']
    xlsx.push(info)
    xlsx.push(headers)
    usersWithErrors.forEach((element: any) => {
      const errors = []
      if (element.user.status === 'failed') {
        errors.push(element.user.message)
      }
      if (element.plan.status === 'failed') {
        errors.push(element.plan.message)
      }
      if (element.company.status === 'failed') {
        errors.push(element.company.message)
      }

      xlsx.push([
        element.name || '',
        element.email || '',
        element.cellphone || '',
        element.document || '',
        errors.join('; '),
      ])
    })

    setDownload({
      id: 1,
      data: {
        'Dados com erros': xlsx,
      },
    })
  }

  const status: any = {
    pending: 'bg-info',
    failed: 'bg-danger',
    completed: 'bg-success',
  }
  const statusName: any = {
    pending: 'Pendente',
    failed: 'Falhou',
    completed: 'Concluido',
  }

  return (
    <Modal isOpen={modal} toggle={toggle} style={{ minWidth: 1000 }}>
      {loading && <Loading title="Aguarde..." />}
      <ModalHeader toggle={toggle}>
        <div>
          <h3>Progresso de importação</h3>
        </div>
      </ModalHeader>
      <div
        style={{
          minWidth: 1000,
          height: '100%',
          maxHeight: 600,
          overflow: 'scroll',
        }}
      >
        <ModalBody className="p-4">
          <Table className="align-items-center  table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Nome</th>
                <th scope="col">CPF</th>
                {/* <th scope="col">Email</th> */}
                {/* <th scope="col">Telefone</th> */}
                <th scope="col">status dados</th>
                <th scope="col">status plano</th>
                <th scope="col">status empresa</th>
              </tr>
            </thead>
            <tbody>
              {data?.users?.map((item: any) => (
                <tr key={item.id}>
                  <th scope="row">
                    <Media className="align-items-center">
                      <Media>
                        <span className="mb-0 text-sm">{item.name}</span>
                      </Media>
                    </Media>
                  </th>
                  <td>{item.document}</td>
                  {/* <td>{item.email}</td> */}
                  {/* <td>{item.cellphone}</td> */}
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                      <i className={status[item.user.status]} />
                      {statusName[item.user.status]}
                    </Badge>
                  </td>
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                      <i className={status[item.plan.status]} />
                      {statusName[item.plan.status]}
                    </Badge>
                  </td>
                  <td>
                    <Badge color="" className="badge-dot mr-4">
                      <i className={status[item.company.status]} />
                      {statusName[item.company.status]}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </ModalBody>
      </div>
      <div className="m-4">
        <span className="mr-2">{`Progresso: ${data?.progress}%`}</span>
        <div>
          <Progress
            max="100"
            value={data?.progress}
            barClassName="bg-warning"
          />
        </div>
      </div>
      <Row className="m-4 justify-content-end">
        {data?.status === 'failed' && (
          <Col md="3" className="d-flex justify-content-end">
            {download.id && download.data ? (
              <ExcelDownloder
                data={download.data}
                filename={`${moment().format('DD/MM/YYYY')} - ${data?.name}`}
                type={Type.Button}
              >
                <Button
                  outline
                  color="primary"
                  onClick={() => {
                    setDownload({
                      id: 0,
                      data: null,
                    })
                  }}
                >
                  <i className="fas fa-download mr-2" />
                  Baixar
                </Button>
              </ExcelDownloder>
            ) : (
              <Button outline color="primary" onClick={creatXlsx}>
                <i className="fas fa-download mr-2" />
                Gerar excel com erros
              </Button>
            )}
          </Col>
        )}
        {data?.status === 'pending' && (
          <Col md="3">
            <Button onClick={getData}>Atualizar</Button>
          </Col>
        )}
      </Row>
    </Modal>
  )
}
export default ModalUserImport
