/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { cpfCnpjMask } from 'js-essentials-functions'

import {
  Button,
  Card,
  CardHeader,
  Col,
  CardBody,
  Input,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Tooltip,
} from 'reactstrap'
import api from 'configs/api'
import PaginationComponent from 'components/PaginationComponent'
import { alertWarning, alertSuccess } from 'utils/toast'
import Loading from 'components/Loading'
import { useLocation, useHistory } from 'react-router-dom'

import NewUserB2b from '../Components/ModalCreateUser'
import ModalCompanies from '../Components/ModalCompanies'

const CollaboratorsCompany = ({ company }: any) => {
  const [usesCompany, setUsersCompany] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState('')
  const [modal, setModal] = useState(false)

  const [modalCompany, setModalCompany] = useState(false)

  const [modalRemove, setModalRemove] = useState(false)
  const [userSelected, setUserSelected] = useState('')

  const params: any = useLocation()
  const history = useHistory()

  const companyId =
    params.pathname.split('/')[params.pathname.split('/').length - 1]
  // Paginação
  const [paginationOn, setPaginationOn] = useState(true)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)
  const limit = 10
  const [typeUser, setTypeUser] = useState<
    'titular' | 'dependente' | 'remove' | ''
  >('')

  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null)

  const toggleTooltip = (id: string) => {
    setVisibleTooltip(visibleTooltip === id ? null : id)
  }

  const getCompany = async (nameUser = name, numberPage = page) => {
    setName('')
    setLoading(true)
    try {
      const response = await api.get(
        `/company/ms/v1/companies/${companyId}/customers?name=${nameUser}&limit=${limit}&page=${numberPage}`
      )

      const datas = response.data.users.map((data: any) => ({
        id: data.customer_id,
        name: data.customer_name,
        document: data.customer_document,
        status: data.status === 'active' ? 'Ativo' : 'Inativo',
        email: data.customer_email,
        is_holder: data.is_holder,
      }))

      if (
        Math.ceil(parseInt(response.data.total) / limit) === 1 ||
        datas.length === 0
      ) {
        setPaginationOn(false)
      } else {
        setPaginationOn(true)
      }
      setUsersCompany(datas)

      setNumberOfPages(Math.ceil(parseInt(response.data.total) / limit))
    } catch (err: any) {
      console.log('error', err)
    }

    setLoading(false)
  }

  const onClickCompany = useCallback(() => {
    history.push(`/admin/importar/${companyId}`, {
      id: companyId,
      name: company?.fantasy_name || 'Nome não informado',
      plan: company?.plan_id,
      type: typeUser,
      email: company?.email,
    })
  }, [company, typeUser])

  const handleRemove = async () => {
    setLoading(true)
    try {
      const response = await api.patch(
        `/company/ms/v1/customer/${userSelected}`
      )
      alertSuccess(response?.data?.message || 'Usuário removido com sucesso')
      setLoading(false)

      getCompany()
    } catch (err: any) {
      alertWarning(err?.response?.data?.message || 'Erro ao remover usuário')
      setLoading(false)
    }
    setModalRemove(false)
  }

  const contentModalRemove = () => (
    <Modal
      isOpen={modalRemove}
      toggle={() => {
        setModalRemove(modalRemove)
      }}
    >
      <ModalHeader>
        <h3>Você deseja realmente remover esse usuário dessa empresa?</h3>
      </ModalHeader>
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="primary"
          onClick={() => {
            handleRemove()
          }}
        >
          Sim
        </Button>
        <Button
          style={{ minWidth: 200 }}
          color="secondary"
          onClick={() => {
            setModalRemove(false)
          }}
        >
          Não
        </Button>
      </ModalBody>
    </Modal>
  )

  useEffect(() => {
    if (typeUser) {
      onClickCompany()
    }
  }, [typeUser])

  useEffect(() => {
    getCompany()
    setTypeUser('')
  }, [page])

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      <CardHeader
        className="bg-transparent border-0"
        style={{ minHeight: 100 }}
      >
        <Row className="mt-3 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <h3 className="mb-0 text-black">{`${company?.legal_name} | Colaboradores`}</h3>
          </Col>
          <Col sm="auto" className="align-items-start d-flex">
            <Button
              style={{ minWidth: 150 }}
              color="secondary"
              onClick={() => {
                setTypeUser('titular')
              }}
            >
              Importar Titulares
            </Button>
            <Button
              sm="auto"
              style={{ minWidth: 220 }}
              color="secondary"
              onClick={() => {
                setTypeUser('dependente')
              }}
            >
              Importar Dependentes
            </Button>
            <Button
              sm="auto"
              style={{ minWidth: 220 }}
              color="primary"
              outline
              onClick={() => {
                setTypeUser('remove')
              }}
              disabled
            >
              Remover Titulares
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody className="shadow">
        <Row className="pt-2 pb-2 align-items-center d-flex justify-content-between">
          <Col sm="auto" className="mb-2">
            <NewUserB2b
              modal={modal}
              setModal={setModal}
              setLoading={setLoading}
              company={{ uuid: companyId, plan_id: company?.plan_id }}
              action={getCompany}
            />
          </Col>
          <Col sm="auto" className="d-flex justify-content-end">
            <Col xs="8">
              <Input
                type="text"
                id="filtrar-cpf"
                maxLength={14}
                value={name}
                placeholder="Filtrar pelo nome..."
                onChange={(e) => setName(e.target.value)}
              />
            </Col>
            <Col xs="auto">
              <Button
                color="secondary"
                id="filter"
                onClick={() => {
                  getCompany(name, 1)
                }}
              >
                <i className="fas fa-search" />
              </Button>
              <Tooltip
                isOpen={visibleTooltip === 'filter'}
                target="filter"
                toggle={() => toggleTooltip('filter')}
              >
                Filtrar
              </Tooltip>
            </Col>
            <Col xs="auto">
              <Button
                color="primary"
                id="ex_filter"
                onClick={() => {
                  setName('')
                  getCompany('', 1)
                }}
              >
                <i className="far fa-trash-alt" />
              </Button>
              <Tooltip
                isOpen={visibleTooltip === 'ex_filter'}
                target="ex_filter"
                toggle={() => toggleTooltip('ex_filter')}
              >
                Apagar filtro
              </Tooltip>
            </Col>
          </Col>
        </Row>

        <Row className="mt-0">
          <div className="col">
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">id</th>
                    <th scope="col">nome</th>
                    <th scope="col">email</th>
                    <th scope="col">cpf</th>
                    <th scope="col">status</th>
                    <th scope="col">Ação</th>
                  </tr>
                </thead>

                <tbody>
                  {usesCompany?.map((user: any) => (
                    <tr key={user.id}>
                      <th scope="row">{user.id}</th>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.document ? cpfCnpjMask(user.document) : ''}</td>
                      <td>
                        {user.status === 'Inativo' ? 'Excluído' : 'Ativo'}
                      </td>
                      <td>
                        {user.is_holder && user.status === 'Ativo' && (
                          <>
                            <Button
                              size="sm"
                              id={`migrar${user?.id}`}
                              color="secondary"
                              onClick={() => {
                                setUserSelected(user.id)
                                setModalCompany(true)
                              }}
                              style={{ width: 40 }}
                            >
                              <i className="far fa-paper-plane" />
                            </Button>
                            <Tooltip
                              isOpen={visibleTooltip === `migrar${user?.id}`}
                              target={`migrar${user?.id}`}
                              toggle={() => toggleTooltip(`migrar${user?.id}`)}
                            >
                              Migrar colaborador
                            </Tooltip>
                          </>
                        )}
                        {user.is_holder && user.status === 'Ativo' && (
                          <>
                            <Button
                              size="sm"
                              color="primary"
                              id={`excluir${user?.id}`}
                              onClick={() => {
                                setUserSelected(user.id)
                                setModalRemove(true)
                              }}
                              style={{ width: 40 }}
                            >
                              <i className="far fa-trash-alt" />
                            </Button>
                            <Tooltip
                              isOpen={visibleTooltip === `excluir${user?.id}`}
                              target={`excluir${user?.id}`}
                              toggle={() => toggleTooltip(`excluir${user?.id}`)}
                            >
                              Excluir colaborador
                            </Tooltip>
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {paginationOn && (
                <PaginationComponent
                  numberOfPages={numberOfPages}
                  page={page}
                  setPage={setPage}
                />
              )}
            </Card>
          </div>
        </Row>
      </CardBody>
      {modalCompany && (
        <ModalCompanies
          setModal={setModalCompany}
          modal={modalCompany}
          userId={userSelected}
          setLoading={setLoading}
          action={getCompany}
        />
      )}
      {contentModalRemove()}
    </div>
  )
}

export default CollaboratorsCompany
