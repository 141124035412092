/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap'
import HeaderNeutro from 'components/Headers/HeaderNeutro'
import { alertWarning } from 'utils/toast'
import Loading from 'components/Loading'

import { getStorage } from 'utils/storage'
import { useDropzone } from 'react-dropzone'
import ReactDropZoneInput from 'components/ReactDropzoneInput'

import PaginationComponent from 'components/PaginationComponent'
import { format } from 'date-fns'

import api from 'configs/api'
import { colaboradores, colaboradoresDep } from './Components/rules'
import ModalUserImport from './Components/ModalUsersImport'

const ImportUser = () => {
  const location = useLocation()
  const { push } = useHistory()
  const company: any = location.state || {}

  const [numberOfPages, setNumberOfPages] = useState(0)
  const [page, setPage] = useState(1)

  const [fileToUpload, setFileToUpload] = useState<any | null>(null)
  const [usersinvalid, setUsersInvalid] = useState<any[]>([])
  const [modalConfirm, setModalConfirm] = useState<boolean>(false)
  const [modalImport, setModalImport] = useState<any>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [historic, setHistoric] = useState<any>('')
  const [typeUser] = useState<any>(
    company.type === 'dependente' ? 'dependent' : 'holder'
  )

  const [invalids, setInvalids] = useState<any>(false)

  const [openState, setOpenState] = useState(
    Array(colaboradores.length).fill(false)
  )

  const dataUser = getStorage('@ManagerStarbem:user')

  // react-dropzone config
  const onDrop = useCallback(async (acceptedFiles: any) => {
    const file = acceptedFiles[0]
    setFileToUpload(file)
  }, [])

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      onDrop,
      accept:
        company.type === 'remove'
          ? '.csv, application/vnd.ms-excel, text/csv'
          : '.xlsx,  text/xlsx',
      multiple: false,
    })

  const getCompany = async () => {
    setLoading(true)
    try {
      const response = await api.get(
        `/accounts/ms/v1/user/import/history?company_id=${company.id}&type=internal&page=${page}&take=10&typeUser=${typeUser}`
      )

      setHistoric(response.data)
      setNumberOfPages(Math.ceil(parseInt(response.data.total, 10) / 10))
    } catch (err: any) {
      console.log('error', err)
    }

    setLoading(false)
  }

  const handleSubmit = async () => {
    try {
      if (!fileToUpload) {
        alertWarning('É necessário informar um arquivo.')
        return
      }

      setLoading(true)
      const formData = new FormData()
      formData.append('file', fileToUpload)
      formData.append('company_id', company?.id)
      formData.append('plan_id', company?.plan)
      formData.append('user_id', dataUser?.id)
      formData.append('user_name', dataUser?.name)
      formData.append('type', 'internal')
      formData.append('company_email', dataUser?.email)
      formData.append('company_name', company?.name)

      let url = ''
      switch (company.type) {
        case 'titular':
          url = '/accounts/ms/v1/user/import'
          break
        case 'dependente':
          url = '/accounts/ms/v1/user/dependent/import'
          break
        case 'remove':
          url = '/manager/b2b/remove'
          break
        default:
          throw new Error('Tipo de empresa inválido')
      }

      const { data } = await api.post(url, formData)

      if (data?.usersErrors?.length > 0) {
        if (company.type === 'dependente') {
          const formattedErrors = data.usersErrors.map((error: any) =>
            typeof error === 'object'
              ? `${error.user}: ${error.message}`
              : error
          )
          setUsersInvalid(formattedErrors)
        } else if (company.type === 'titular') {
          const invalidCpfs = data.usersErrors.map((item: any) => item)
          setUsersInvalid(invalidCpfs)
        }
        setInvalids(true)
      } else {
        acceptedFiles.shift()

        setModalConfirm(true)
        // alertSuccess(data?.message || 'Sucesso!')
      }
    } catch (err: any) {
      alertWarning(
        err?.response?.data?.message || 'Ocorreu um erro durante a importação.'
      )
    } finally {
      await getCompany()
      setLoading(false)
    }
  }

  const toggleCollapsible = (index: any) => {
    const newOpenState = [...openState]
    newOpenState[index] = !newOpenState[index]
    setOpenState(newOpenState)
  }

  useEffect(() => {
    getCompany()
  }, [page])

  const contentModalConfirm = () => (
    <Modal
      isOpen={modalConfirm}
      toggle={() => {
        setModalConfirm(false)
      }}
    >
      <ModalHeader>
        <h3>
          Olá! A importação de dados dos usuários está sendo processada, e as
          informações estarão disponíveis na plataforma em até 6 horas. Caso
          ocorram problemas, você será notificado por e-mail.
        </h3>
      </ModalHeader>
      <ModalBody className="p-4 d-flex justify-content-between">
        <Button
          color="secondary"
          onClick={() => {
            setModalConfirm(false)
            // eslint-disable-next-line no-underscore-dangle
            setModalImport(historic?.history?.[0]?._id)
          }}
        >
          Acompanhar
        </Button>
        <Button
          color="primary"
          onClick={() => {
            setModalConfirm(false)
          }}
        >
          Fechar
        </Button>
      </ModalBody>
    </Modal>
  )

  const ContentCpfsInvalid = () => (
    <div className="d-flex flex-column justify-content-space-between">
      <div className="text-center">
        <h1 className="text-uppercase">Dados inválidos</h1>
        <p className="font-weight-bold text-muted">
          {invalids && 'Os seguintes usuários possuem dados inválidos:'}
        </p>
      </div>
      <div
        className="d-flex flex-column h-100 justify-content-center"
        style={{ flex: 1, height: '68%', overflowY: 'auto' }}
      >
        <ul>
          {company.type === 'titular' &&
            usersinvalid.map((item) => (
              <li key={item} className="font-italic">
                {item.user}
                <span> - </span>
                {item.message}
              </li>
            ))}

          {company.type === 'dependente' &&
            usersinvalid.map((item) => (
              <li key={item} className="font-italic">
                {item}
              </li>
            ))}
        </ul>
      </div>
      <div
        className="d-flex align-items-end justify-content-end"
        style={{ height: '12%' }}
      >
        <div>
          <Button
            disabled={!fileToUpload}
            onClick={() => {
              setFileToUpload(null)
              setUsersInvalid([])
              setInvalids(false)
              acceptedFiles.shift()
            }}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  )

  return (
    <div>
      {loading && <Loading title="Aguarde..." />}
      {contentModalConfirm()}
      <ModalUserImport
        modal={!!modalImport}
        id={modalImport}
        setModal={setModalImport}
      />
      <HeaderNeutro />
      <Container className="mt-3" fluid>
        <Row className="mt-3 align-items-end d-flex justify-content-between">
          <Col sm="auto" className="align-items-start ">
            <Button
              color="secondary"
              onClick={() => {
                push(`/admin/empresa/${company?.id}`)
              }}
            >
              <i className="fas fa-arrow-left" />
            </Button>
          </Col>
        </Row>
      </Container>
      <Container className="mt-3" fluid>
        <Card className="shadow">
          <CardHeader className="bg-transparent d-flex justify-content-between align-items-center">
            <Col sm="auto">
              <h2 className="mb-0 text-black">
                {company.type === 'titular' && 'Importação de Titulares'}
                {company.type === 'dependente' && 'Importação de Dependente'}
                {company.type === 'remove' && 'Remoção Titulares'}
              </h2>
            </Col>
          </CardHeader>

          <CardBody className="shadow">
            <Row className="m-1">
              <Col lg="6" className="lg: mb-5">
                <h3 className="text-black">Como preencher Excel (.xlsx)</h3>
                <p>
                  Algumas regras de preenchimento de cada coluna da planilha
                  modelo
                </p>

                {company.type === 'titular' &&
                  colaboradores.map((colaborador) => (
                    <div key={colaborador.id}>
                      <div
                        className="collapsible d-flex justify-content-between text-primary"
                        onClick={() => toggleCollapsible(colaborador.id)}
                        role="button"
                        tabIndex={0}
                        aria-expanded={openState[colaborador.id]}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            toggleCollapsible(colaborador.id)
                        }}
                      >
                        {colaborador.title}
                        <i
                          className={`fas ${
                            openState[colaborador.id]
                              ? 'fa-chevron-up'
                              : 'fa-chevron-down'
                          }`}
                          style={{ fontSize: '15px', marginLeft: '10px' }}
                        />
                      </div>
                      {openState[colaborador.id] && (
                        <div className="content">
                          <p>{colaborador.text}</p>
                        </div>
                      )}
                    </div>
                  ))}
                {company.type === 'dependente' &&
                  colaboradoresDep.map((colaborador) => (
                    <div key={colaborador.id}>
                      <div
                        className="collapsible d-flex justify-content-between text-primary"
                        onClick={() => toggleCollapsible(colaborador.id)}
                        role="button"
                        tabIndex={0}
                        aria-expanded={openState[colaborador.id]}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter' || e.key === ' ')
                            toggleCollapsible(colaborador.id)
                        }}
                      >
                        {colaborador.title}
                        <i
                          className={`fas ${
                            openState[colaborador.id]
                              ? 'fa-chevron-up'
                              : 'fa-chevron-down'
                          }`}
                          style={{ fontSize: '15px', marginLeft: '10px' }}
                        />
                      </div>
                      {openState[colaborador.id] && (
                        <div className="content">
                          <p>{colaborador.text}</p>
                        </div>
                      )}
                    </div>
                  ))}
              </Col>

              <Col lg="6">
                <h3 className="text-black">Adicionar planilha</h3>
                <p>
                  Selecione o arquivo com as informações dos seus colaboradores
                </p>
                {usersinvalid.length > 0 && <ContentCpfsInvalid />}
                {usersinvalid.length === 0 && (
                  <>
                    <div
                      className="d-flex flex-column justify-content-center mt-4 mb-4 "
                      style={{ flex: 1, padding: 0, margin: 0 }}
                    >
                      <ReactDropZoneInput getRootProps={getRootProps()}>
                        <input {...getInputProps()} />
                        {isDragActive ? (
                          <p
                            className="font-weight-bold text-muted "
                            style={{ fontSize: 14 }}
                          >
                            Solte o arquivo aqui...
                          </p>
                        ) : acceptedFiles.length > 0 ? (
                          <p
                            className="font-weight-bold text-muted"
                            style={{ fontSize: 14 }}
                          >
                            {acceptedFiles[0].name}
                          </p>
                        ) : (
                          <>
                            <i
                              className="fas fa-file"
                              style={{ fontSize: '40px', marginBottom: '10px' }}
                            />
                            <p
                              className="font-weight-bold"
                              style={{ fontSize: 14 }}
                            >
                              Arraste e solte o arquivo aqui, ou clique para
                            </p>
                            <Button
                              style={{ marginBottom: '10px' }}
                              color="primary"
                            >
                              Selecionar arquivo
                            </Button>
                            <p
                              className="font-weight-bold text-muted"
                              style={{ fontSize: 14 }}
                            >
                              A extensão do arquivo deve ser .xlsx e pode ter
                              até 1000 colaboradores. O nome do arquivo não pode
                              conter caracteres especiais e não pode ultrapassar
                              50 caracteres.
                            </p>
                          </>
                        )}
                      </ReactDropZoneInput>
                    </div>

                    <div>
                      <Button
                        disabled={
                          !fileToUpload ||
                          (!fileToUpload && company.type === 'dependente')
                        }
                        onClick={() => handleSubmit()}
                      >
                        {company.type === 'remove' ? 'Remover' : 'Importar'}
                      </Button>
                    </div>
                    <div className="d-flex justify-content-between mt-4">
                      <p className="font-weight-bold">
                        Para ver um exemplo do formato obrigatório:
                      </p>
                      {company.type === 'dependente' && (
                        <Button
                          color="primary"
                          outline
                          href="https://starbem-production.nyc3.digitaloceanspaces.com/generic/import-b2b/Importador%20de%20colaboradores%20dependentes.xlsx"
                          className="lg-3"
                        >
                          Baixar modelo
                        </Button>
                      )}
                      {company.type === 'titular' && (
                        <Button
                          color="primary"
                          outline
                          href="https://docs.google.com/spreadsheets/d/18YJtZ_CJyQ2JdJWk71JSiic_aSij_vZZNnfFQhAkzMo/edit?gid=2040882291#gid=2040882291"
                          className="lg-3"
                          target="_blank"
                        >
                          Abrir modelo
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </CardBody>
          <Card className="shadow">
            <Col className="mt-4 mb-4">
              <h3 className="text-black">
                {company.type === 'titular' &&
                  'Histórico de importações titulares'}
                {company.type === 'dependente' &&
                  'Histórico de importações dependentes'}
                {company.type === 'remove' &&
                  'Histórico de remoção de titulares'}
              </h3>
            </Col>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Ação</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Nome da planilha</th>
                  <th scope="col">Quantidade de importados</th>
                  <th scope="col">Quantidade de falhas</th>
                  <th scope="col">Data e hora</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>

              <tbody>
                {historic?.history?.map((user: any) => (
                  // eslint-disable-next-line no-underscore-dangle
                  <tr key={user._id}>
                    <td>
                      <div>
                        <Button
                          onClick={() => {
                            // eslint-disable-next-line no-underscore-dangle
                            setModalImport(user._id)
                          }}
                        >
                          Ver
                        </Button>
                      </div>
                    </td>
                    <th>{user.userName}</th>
                    <td>{user.name}</td>
                    <td>{user.quantityImport}</td>
                    <td>{user.quantityErrors}</td>
                    <td>
                      {user?.date
                        ? `${format(
                            new Date(user?.date),
                            'dd/MM/yyyy'
                          )} - ${format(new Date(user?.date), 'HH:mm')}`
                        : ''}
                    </td>
                    <td
                      style={
                        user.status === 'imported'
                          ? {
                              color: '#009847',
                            }
                          : { color: '#DD2E3A' }
                      }
                    >
                      {user.status === 'imported' ? 'Concluído' : 'Falhou'}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <PaginationComponent
              numberOfPages={numberOfPages}
              page={page}
              setPage={setPage}
            />
          </Card>
        </Card>
      </Container>
    </div>
  )
}

export default ImportUser
